var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",staticStyle:{"text-align":"left"}},[_c('Breadcrumb',{staticClass:"custom-breadcrumb"},[_c('BreadcrumbItem',[_vm._v("应试管理")]),_c('BreadcrumbItem',{attrs:{"to":{ path: '/candidate/candidateManage' }}},[_vm._v("应试者管理")]),_c('BreadcrumbItem',[_vm._v(_vm._s(_vm.$route.query.name)+" 的考试")])],1),_c('div',{staticClass:"bg-shadow func-bar"},[_c('div',{staticClass:"button-container"},[_c('Button',{staticClass:"filter-btn btn-shadow",attrs:{"type":"primary"},on:{"click":_vm.delExam}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-shanchu"}})]),_vm._v(" 删除 ")])],1)]),_c('div',{staticClass:"table-container bg-shadow"},[_c('Table',{staticClass:"flex-table",attrs:{"stripe":"","columns":_vm.columns,"data":_vm.tableData,"loading":_vm.loading},on:{"on-selection-change":_vm.select,"on-row-click":_vm.toDetail},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.examName))])]}},{key:"status",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.status(row.status)))])]}},{key:"score",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.score))])]}},{key:"duration",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.timeStamp(row.duration)))])]}},{key:"expires",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.expiresTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"startTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.startTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"submit",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.submitTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"operation",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',[_c('Tooltip',{attrs:{"content":"编辑","placement":"top"}},[_c('svg',{staticClass:"icon",staticStyle:{"cursor":"pointer"},attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.edit(row)}}},[_c('use',{attrs:{"xlink:href":"#icon-bianji"}})])]),_c('Tooltip',{staticStyle:{"margin-left":"10px"},attrs:{"content":"删除","placement":"top"}},[_c('svg',{staticClass:"icon",staticStyle:{"cursor":"pointer"},attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.delCandidate(row.candidateId)}}},[_c('use',{attrs:{"xlink:href":"#icon-shanchu"}})])])],1)]}}])})],1),_c('Modal',{attrs:{"title":"评测结果"},model:{value:(_vm.resModal),callback:function ($$v) {_vm.resModal=$$v},expression:"resModal"}},[_vm._l((_vm.result),function(item,index){return _c('div',{key:index,staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"font-size":"1.2em","font-weight":"bold"}},[_vm._v(_vm._s(item.sectionName)+": "+_vm._s(item.score)+"分")]),_c('br'),_c('span',{staticStyle:{"font-size":"1.1em"},domProps:{"innerHTML":_vm._s(item.result === null ? '' : item.result.replace(/(\r\n)|(\n)/g, '<br>'))}})])}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.resModal = false}}},[_vm._v("确认")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }